(function($) {
    'use strict';

    if (window.location.hash) {
        setTimeout(function() {
            window.scrollTo(0, 0);
        }, 1);
        var link_id = window.location.hash.split('#').pop();
        var destination = $('[data-row-label="' + link_id +'"]');
        if(destination.length > 0) {
            $('html, body').animate({
                scrollTop: destination.offset().top -96
            }, 2000);
        }
    }

    /**
     * Register click events on sub navigation toggles
     */
    $('.menu > .menu__item--has-children > a').on('click', function(e) {
        let parent = $(this).parent()
        let menu = $(this).closest('ul')

        if (Foundation.MediaQuery.current === 'small') {
            if(!parent.hasClass('is-active')) e.preventDefault()

            $('.c-nav-primary').addClass('is-active')

            menu.find('.menu__item').removeClass('is-active')

            parent.addClass('is-active')
        }
    });

    $('.menu a[href*="#"]').on('click', function(e) {
        var link = $(this).attr('href');
        var link_id = link.split('#').pop();
        var destination = $('[data-row-label="' + link_id +'"]');
        if(destination.length > 0) {
            e.preventDefault();
            $('body').removeClass('navigation--is-active');
            $('html, body').animate({
                scrollTop: destination.offset().top -96
            }, 2000);
        }
    });

    $('.c-page-header__menu .menu .menu__item--wpml_ls_menu_item.menu__item--has-children > a').on("click touchstart", function(event) {
        event.preventDefault();
        $(this).parent().toggleClass('is-active');
    })

    // if($('.c-media-group--hero').length > 0) {
    //     $('.c-media-group--hero .js-media-group-item:first-child').addClass('left');
    //     $('.c-media-group--hero .js-media-group-item:first-child').next().addClass('right');
    // }

})(jQuery);
